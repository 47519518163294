import { Suspense, lazy, useEffect } from "react";
import shallow from "zustand/shallow";
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";

import { useAuth } from "./lib/auth-provider/context";
import { useProfile } from "./lib/auth-provider/context/hooks";

import { FullPageSpinner } from "./common/components";

const FullPageError = lazy(() => import("./common/components/full-page-error"));
const Home = lazy(() => import("./modules/home"));
const ProfileAdmin = lazy(() => import("./modules/profile-admin"));
const LoginModules = lazy(() => import("./modules/login"));
const ExampleModules = lazy(() => import("./modules/example"));
const EmployeeModules = lazy(() => import("./modules/employee"));
const SettingModules = lazy(() => import("./modules/setting"));
const DirectorateModules = lazy(() => import("./modules/directorate"));
const AttendanceModules = lazy(() => import("./modules/attendance"));
const ShiftingModules = lazy(() => import("./modules/shifting"));
const AdminModules = lazy(() => import("./modules/admin"));
const ReportModules = lazy(() => import("./modules/report"));
const Product = lazy(() => import("./modules/product"));
const Announcment = lazy(() => import("./modules/announcement"));
const Mading = lazy(() => import("./modules/mading"));
const ShopSales = lazy(() => import("./modules/sales-toko"));
const OutSideSales = lazy(() => import("./modules/sales-lapangan"));
const ReportOutSideSales = lazy(() =>
  import("./modules/report-sales-lapangan")
);
const MapsOutSideSales = lazy(() => import("./modules/maps-sales-lapangan"));
const MapsSprinterSales = lazy(() => import("./modules/maps-sales-sprinter"));
const DashboardOutSideSales = lazy(() =>
  import("./modules/dashboard-sales-lapangan")
);
const DashboardSprinterSales = lazy(() =>
  import("./modules/dashboard-sales-sprinter")
);
const DashboardShopSales = lazy(() => import("./modules/dashboard-sales-toko"));
const ReportShopSales = lazy(() => import("./modules/report-sales-toko"));
const MapsShopSales = lazy(() => import("./modules/maps-sales-toko"));
const SprinterSales = lazy(() => import("./modules/sales-sprinter"));
const ReportSprinterSales = lazy(() =>
  import("./modules/report-sales-sprinter")
);
const Client = lazy(() => import("./modules/client"));
const Vacation = lazy(() => import("./modules/vacation"));
const Salary = lazy(() => import("./modules/salary"));
const Reporting = lazy(() => import("./modules/reporting-activity"));
const AttendanceManagement = lazy(() =>
  import("./modules/attendance-management")
);
const AttendanceMaps = lazy(() => import("./modules/attendance-maps"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [getAuth] = useAuth((state) => [state.getAuth], shallow);

  const { refetch: getProfile } = useProfile();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  useEffect(() => {
    const currentUser = getAuth();
    if (currentUser.token) {
      getProfile();
    }
  }, [getAuth, getProfile]);

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Routes>
        <Route path="/home/*" element={<Home />} />
        <Route path="/profile-admin/*" element={<ProfileAdmin />} />
        {/* ShopSales */}
        <Route path="/product/*" element={<Product />} />
        <Route path="/shop-sales/*" element={<ShopSales />} />
        <Route path="/login/*" element={<LoginModules />} />
        <Route path="/sprinter-sales/*" element={<SprinterSales />} />
        <Route
          path="/report-sprinter-sales/*"
          element={<ReportSprinterSales />}
        />
        <Route path="/outside-sales/*" element={<OutSideSales />} />
        <Route
          path="/report-outside-sales/*"
          element={<ReportOutSideSales />}
        />
        <Route path="/maps-outside-sales/*" element={<MapsOutSideSales />} />
        <Route path="/maps-sprinter-sales/*" element={<MapsSprinterSales />} />
        <Route
          path="/dashboard-outside-sales/*"
          element={<DashboardOutSideSales />}
        />
        <Route
          path="/dashboard-sprinter-sales/*"
          element={<DashboardSprinterSales />}
        />
        <Route
          path="/dashboard-shop-sales/*"
          element={<DashboardShopSales />}
        />
        <Route path="/report-shop-sales/*" element={<ReportShopSales />} />
        <Route path="/maps-shop-sales/*" element={<MapsShopSales />} />
        <Route path="/client/*" element={<Client />} />
        <Route
          path="/attendance-management/*"
          element={<AttendanceManagement />}
        />
        <Route path="/maps/*" element={<AttendanceMaps />} />
        <Route path="/example/*" element={<ExampleModules />} />
        <Route path="/pengumuman/*" element={<Mading />} />
        <Route path="/announcement/*" element={<Announcment />} />
        <Route path="/employee/*" element={<EmployeeModules />} />
        <Route path="/setting/*" element={<SettingModules />} />
        <Route path="/directorate/*" element={<DirectorateModules />} />
        <Route path="/attendance/*" element={<AttendanceModules />} />
        <Route path="/shifting/*" element={<ShiftingModules />} />
        <Route path="/admin/*" element={<AdminModules />} />
        <Route path="/report/*" element={<ReportModules />} />
        <Route path="/vacation/*" element={<Vacation />} />
        <Route path="/salary/*" element={<Salary />} />
        <Route path="/reporting/*" element={<Reporting />} />
        <Route
          path="*"
          element={<FullPageError message="Page not found" errorCode={404} />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
